import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { publicUrl } from "../../constants/endpoints";
import { Link } from "react-router-dom";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import VideoModal from "../section-components/video-modal";
import Lottie from "lottie-react";
import left_arrow_animation from '../../assets/animation/left_arrow_animation.json'
import right_arrow_animation from '../../assets/animation/right_arrow_animation.json'
import { useTranslation } from "react-i18next";

const About = () => {
  const { t, i18n } = useTranslation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const [showModal, setShowModal] = useState(false);
  const handleOpenModal = () => {
    setShowModal(true);
  }
  const handleCloseModal = () => {
    setShowModal(false);
  }
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const containerClasses = `about-area ${isMobile ? '' : 'pd-top-60'} pd-bottom-90`;

  return (
    <>
      <VideoModal isMobile={isMobile} showModal={showModal} handleCloseModal={handleCloseModal} modalHeadTitle={t('about us')} youtubeVideoId="SMixSPwjaZA" />
      <div className={containerClasses} ref={ref} id="about">
        <div className="container-fluid">
          <div className="about-area-inner">
            <div className="row">
              {
                i18n.language === "ar"
                ?
                  <>
                    <div className="col-lg-5"/>
                    <div className={`col-lg-4 text-right ${inView ? "about-animate-right" : ""}`}>
                      <div className="about-inner-wrap pl-xl-5 pt-5 pt-lg-0 mt-5 mt-lg-0">
                        <div className="section-title mb-0">
                          <h6 className="sub-title left-line">{t('about us')}</h6>
                          <h2 className="title">{t('about us question')}</h2>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3"></div>
                  </>
                :
                <>
                  <div className="col-lg-3"></div>
                  <div className={`col-lg-9 ${inView ? "about-animate-right" : ""}`}>
                    <div className="about-inner-wrap pl-xl-4 pt-5 pt-lg-0 mt-5 mt-lg-0">
                      <div className="section-title mb-0">
                        <h6 className="sub-title right-line">{t('about us')}</h6>
                        <h2 className="title">{t('about us question')}</h2>
                      </div>
                    </div>
                  </div>
                </>
              }
              {
                isMobile
                ? null
                : <div className="col-md-3">
                  <Lottie
                    animationData={left_arrow_animation} 
                    loop={false}
                    style={{ height: "150px" }}
                  />
                  <h4 className="text-center" style={{ fontWeight: "lighter" }}>
                    {t('see the video')}
                  </h4>
                </div>
              }
              <div className="col-md-6">
                <div className="single-intro-inner style-icon-bg text-center" style={{ position: "relative" }}>
                  <img
                    style={{ height: "auto", borderRadius: "10px" }}
                    src={publicUrl + "assets/img/about/video-cover-page.jpg"}
                    alt="img"
                  />
                  <div style={{ backgroundColor: "white", height: `${ isMobile ? "50px" : "100px" }`, position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", borderRadius: "50%"}}>
                    <span onClick={() => handleOpenModal()} style={{ color: "gold", fontWeight: "bold", display: "inline-block", animation: "bounce 1s infinite alternate" }}>
                      <PlayCircleIcon style={{ fontSize: `${ isMobile ? "50px" : "100px" }`, transition: "transform 0.3s ease-in-out", cursor: "pointer" }} />
                    </span>
                  </div>
                </div>
                  <h5 className="text-center pb-3" style={{ fontWeight: "lighter" }}>{t('about us description')}</h5>
              </div>
              {
                isMobile
                ? null
                : <div className="col-md-3 pt-5">
                  <h4 className="text-center" style={{ fontWeight: "lighter" }}>
                    {t("want to know us question")}
                  </h4>
                  <Lottie 
                    animationData={right_arrow_animation} 
                    loop={false}
                    style={{ height: "150px" }}
                  />
                </div>
              }
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-3"></div>
                  <div className="col-md-3" style={{ cursor: "pointer" }}>
                    <Link to="https://app.talabatn.net/authentication/signup" target="_blank">
                      <div className="single-intro-inner style-icon-bg text-center">
                        <img
                          style={{ height: "150px" }}
                          src={publicUrl + "assets/img/talabaJ.png"}
                          alt="img"
                        />
                        <div className="details">
                          <p>{t('dedicated for highschool students')}</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-3" style={{ cursor: "pointer" }}>
                    <Link to="https://app.talabatn.net/authentication/signup" target="_blank">
                      <div className="single-intro-inner style-icon-bg text-center">
                        <img
                          style={{ height: "150px" }}
                          src={publicUrl + "assets/img/talabaLogo.png"}
                          alt="img"
                        />
                        <div className="details">
                          <p>{t("dedicated for college students")}</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
